import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {

  public security: any = null;
  public avatar: any = null;

  constructor(
    private commonService: CommonService,
    private router: Router
  ) {
    
  }

  ngOnInit() {
    this.security = this.commonService.getUser();
    if (this.security.role != 'admin') {
      this.router.navigate(['/manager']);
    }
    if (this.security) {
      if (this.security.avatar == 'users/default.png') {
        this.avatar = null;
      } else {
        this.avatar = environment.link_api+'/storage/'+this.security.avatar;
      }
    }
  }


}
