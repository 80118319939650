/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Job } from '../models/job';
import { JobCreate } from '../models/job-create';
import { TypeAny } from '../models/type-any';
import { Statistic } from '../models/statistic';


@Injectable()
export class JobsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param to_date - undefined
   * @param status - undefined
   * @param sort_type - undefined
   * @param sort_by - undefined
   * @param search - undefined
   * @param page - undefined
   * @param my_job - undefined
   * @param limit - undefined
   * @param from_date - undefined
   */
  jobsResponse(params: JobsService.JobsParams): Observable<HttpResponse<Job[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toDate != null) __params = __params.set("to_date", params.toDate.toString());
    if (params.status != null) __params = __params.set("status", params.status.toString());
    if (params.sortType != null) __params = __params.set("sort_type", params.sortType.toString());
    if (params.sortBy != null) __params = __params.set("sort_by", params.sortBy.toString());
    if (params.search != null) __params = __params.set("search", params.search.toString());
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.myJob != null) __params = __params.set("my_job", params.myJob.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    if (params.fromDate != null) __params = __params.set("from_date", params.fromDate.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/jobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Job[] = null;
        _body = _resp.body as Job[]
        return _resp.clone({body: _body}) as HttpResponse<Job[]>;
      })
    );
  }

  /**
   * @param to_date - undefined
   * @param status - undefined
   * @param sort_type - undefined
   * @param sort_by - undefined
   * @param search - undefined
   * @param page - undefined
   * @param my_job - undefined
   * @param limit - undefined
   * @param from_date - undefined
   */
  jobs(params: JobsService.JobsParams): Observable<Job[]> {
    return this.jobsResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * create a Job
   * @param body - create
   */
  createJobResponse(body?: TypeAny): Observable<HttpResponse<JobCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/jobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: JobCreate = null;
        _body = _resp.body as JobCreate
        return _resp.clone({body: _body}) as HttpResponse<JobCreate>;
      })
    );
  }

  /**
   * create a Job
   * @param body - create
   */
  createJob(body?: TypeAny): Observable<JobCreate> {
    return this.createJobResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param uuid - undefined
   */
  deleteJobResponse(uuid: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uuid != null) __params = __params.set("uuid", uuid.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/jobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param uuid - undefined
   */
  deleteJob(uuid: string): Observable<TypeAny> {
    return this.deleteJobResponse(uuid).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param uuid - undefined
   */
  stopJobResponse(uuid?: string): Observable<HttpResponse<Job>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uuid != null) __params = __params.set("uuid", uuid.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/jobs/stop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Job = null;
        _body = _resp.body as Job
        return _resp.clone({body: _body}) as HttpResponse<Job>;
      })
    );
  }

  /**
   * @param uuid - undefined
   */
  stopJob(uuid?: string): Observable<Job> {
    return this.stopJobResponse(uuid).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param user_id - undefined
   * @param to_date - undefined
   * @param software_id - undefined
   * @param project_uuid - undefined
   * @param page - undefined
   * @param limit - undefined
   * @param from_date - undefined
   */
  statisticListResponse(params: JobsService.StatisticListParams): Observable<HttpResponse<Job[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set("user_id", params.userId.toString());
    if (params.toDate != null) __params = __params.set("to_date", params.toDate.toString());
    if (params.softwareId != null) __params = __params.set("software_id", params.softwareId.toString());
    if (params.projectUuid != null) __params = __params.set("project_uuid", params.projectUuid.toString());
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    if (params.fromDate != null) __params = __params.set("from_date", params.fromDate.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/jobs/statistic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Job[] = null;
        _body = _resp.body as Job[]
        return _resp.clone({body: _body}) as HttpResponse<Job[]>;
      })
    );
  }

  /**
   * @param user_id - undefined
   * @param to_date - undefined
   * @param software_id - undefined
   * @param project_uuid - undefined
   * @param page - undefined
   * @param limit - undefined
   * @param from_date - undefined
   */
  statisticList(params: JobsService.StatisticListParams): Observable<Job[]> {
    return this.statisticListResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param user_id - undefined
   * @param to_date - undefined
   * @param software_id - undefined
   * @param project_uuid - undefined
   * @param from_date - undefined
   */
  statisticDashboardResponse(params: JobsService.StatisticDashboardParams): Observable<HttpResponse<Statistic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set("user_id", params.userId.toString());
    if (params.toDate != null) __params = __params.set("to_date", params.toDate.toString());
    if (params.softwareId != null) __params = __params.set("software_id", params.softwareId.toString());
    if (params.projectUuid != null) __params = __params.set("project_uuid", params.projectUuid.toString());
    if (params.fromDate != null) __params = __params.set("from_date", params.fromDate.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/jobs/statistic/dashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Statistic = null;
        _body = _resp.body as Statistic
        return _resp.clone({body: _body}) as HttpResponse<Statistic>;
      })
    );
  }

  /**
   * @param user_id - undefined
   * @param to_date - undefined
   * @param software_id - undefined
   * @param project_uuid - undefined
   * @param from_date - undefined
   */
  statisticDashboard(params: JobsService.StatisticDashboardParams): Observable<Statistic> {
    return this.statisticDashboardResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param year - undefined
   * @param user_id - undefined
   * @param to_week - undefined
   * @param software_id - undefined
   * @param project_uuid - undefined
   * @param from_week - undefined
   */
  jobChartResponse(params: JobsService.JobChartParams): Observable<HttpResponse<Statistic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.year != null) __params = __params.set("year", params.year.toString());
    if (params.userId != null) __params = __params.set("user_id", params.userId.toString());
    if (params.toWeek != null) __params = __params.set("to_week", params.toWeek.toString());
    if (params.softwareId != null) __params = __params.set("software_id", params.softwareId.toString());
    if (params.projectUuid != null) __params = __params.set("project_uuid", params.projectUuid.toString());
    if (params.fromWeek != null) __params = __params.set("from_week", params.fromWeek.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/jobs/chart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Statistic = null;
        _body = _resp.body as Statistic
        return _resp.clone({body: _body}) as HttpResponse<Statistic>;
      })
    );
  }

  /**
   * @param year - undefined
   * @param user_id - undefined
   * @param to_week - undefined
   * @param software_id - undefined
   * @param project_uuid - undefined
   * @param from_week - undefined
   */
  jobChart(params: JobsService.JobChartParams): Observable<Statistic> {
    return this.jobChartResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param uuid - undefined
   */
  downloadResultResponse(uuid: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/jobs/${uuid}/download-result`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param uuid - undefined
   */
  downloadResult(uuid: string): Observable<TypeAny> {
    return this.downloadResultResponse(uuid).pipe(
      map(_r => _r.body)
    );
  }}

export module JobsService {
  export interface JobsParams {
    toDate?: string;
    status?: string;
    sortType?: string;
    sortBy?: string;
    search?: string;
    page?: number;
    myJob?: string;
    limit?: number;
    fromDate?: string;
  }
  export interface StatisticListParams {
    userId?: number;
    toDate?: string;
    softwareId?: number;
    projectUuid?: string;
    page?: number;
    limit?: number;
    fromDate?: string;
  }
  export interface StatisticDashboardParams {
    userId?: number;
    toDate?: string;
    softwareId?: number;
    projectUuid?: string;
    fromDate?: string;
  }
  export interface JobChartParams {
    year?: number;
    userId?: number;
    toWeek?: number;
    softwareId?: number;
    projectUuid?: string;
    fromWeek?: number;
  }
}
