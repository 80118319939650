<header class="py-3 mb-3 border-bottom">
  <div class="container-fluid d-grid gap-3 align-items-center" style="grid-template-columns: 1fr 2fr;">
    <div>
      <a class="d-flex align-items-center logo-header" [routerLink]="['/manager']">
        <img class="cursor-pointer" src="assets/image/logo.png" [routerLink]="['/manager']">
      </a>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <div class="me-2 fw-bold">{{ security.name }}</div>
      <div class="flex-shrink-0 dropdown">
        <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown"
          aria-expanded="false">
          <img [src]="avatar ? avatar : 'assets/image/avatar.png'" alt="avatar" width="32" height="32"
            class="rounded-circle">
        </a>
        <ul class="dropdown-menu text-small shadow">
          <li><a class="dropdown-item" [routerLink]="['/manager/statistic']" *ngIf="security.role == 'admin'">Dashboard</a></li>
          <li><a class="dropdown-item" [routerLink]="['/auth/signout']">Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</header>