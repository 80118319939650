import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../app/api/services';
import { Login } from '../app/api/models';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  private login: Login;
  
  constructor(
    translate: TranslateService,
    private authService: AuthService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    this.login = new Login();
    this.tokenTracking();
  }
  
  tokenTracking() {
    let waitTime = environment.time_refresh_token; // 1 hours
    let executionTime: any;
    let initialTime = localStorage.getItem("initialTime");
    if (initialTime === null) {
        localStorage.setItem("initialTime", (new Date()).getTime().toString());
        executionTime = waitTime;
    } else {
        executionTime = parseInt(initialTime, 10) + waitTime - (new Date()).getTime();
        if (executionTime < 0) executionTime = 0;
    }
    let timeLogin = setTimeout(()=>{
      // reset the timeout to start from waitTime on page reload
      this.login.refresh_token = localStorage.getItem('refresh_token');
      this.authService.refreshToken(this.login).subscribe(
        res => {
          //console.log('refresh_token');
          localStorage.removeItem("initialTime");
          localStorage.setItem('token', res.access_token);
          localStorage.setItem('refresh_token', res.refresh_token);
          this.tokenTracking();
        },
        err => {
          clearTimeout(JSON.parse(localStorage.getItem('timelogin')));
        }
      );
    }, executionTime);
    localStorage.setItem('timelogin', JSON.stringify(timeLogin));
  }
  
}