import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public tokenCrypto: string = "01B34q@7a9&W34f6";

  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
    
  }

  // Return false if field input is null or empty;
  public isNullOrEmpty(input) {
    if (input == undefined) {
      return true;
    } else if (input == '') {
      return true;
    }
    return false;
  }

  public nameInvalid(input) {
    let regex = /^[a-zA-Z0-9_]*$/;
    if (input.match(regex)) {
      return true;
    }
    return false;
  }

  public nameUserInvalid(input) {
    let regex = /^[a-zA-Z0-9 _]*$/;
    if (input.match(regex)) {
      return true;
    }
    return false;
  }

  public isEmailInvalid(input) {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (input.match(regex)) {
      return false;
    }
    return true;
  }

  public isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  public isScientific(n) {
    let decimal = (n).toLocaleString('fullwide', {useGrouping:false});
    return !isNaN(parseFloat(decimal)) && isFinite(decimal);
  }

  public isNotNumber(input) {
    return isNaN(input);
  }

  setCookie(name: string, value: string, expireDays: number, path: string = '') {
    value = this.encrypt(value);
    let d:Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires:string = `expires=${d.toUTCString()}`;
    let cpath:string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return this.decrypt(c.substring(cookieName.length, c.length));
        }
    }
    return '';
  }

  deleteCookie(name: string) {
      this.setCookie(name, '', -1);
  }

  public encrypt(req: any) {
    if (req) {
      let _key = CryptoJS.enc.Utf8.parse(this.tokenCrypto);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenCrypto);
      let data = req;
      if (typeof req === 'object') data = JSON.stringify(req);
      let encrypted = CryptoJS.AES.encrypt(
          data, _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
      return encrypted.toString();
    }
  }

  public decrypt(req: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenCrypto);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenCrypto);
    let decrypted = CryptoJS.AES.decrypt(
        req, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  setUser(user: any) {
    let value = this.encrypt(user);
    localStorage.setItem('abaqus-user', value);
  }

  getUser() {
    let value = localStorage.getItem('abaqus-user');
    if (value) {
      return JSON.parse(this.decrypt(value));
    } else {
      localStorage.clear();
      this.router.navigate(['/auth/signin']);
    }
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  roundCustom(num, limit) {
    let a = (num).toString();
    return Number(a.substring(0, a.indexOf('.') + 7)).toFixed(limit);
  }

  public sortBy(fieldSort: any, iconSort: any) {
    let promise = new Promise((resolve, reject) => {
      let res = { iconSort: '', columnSort: '', typeSort: '' }
      if (iconSort == environment.column.sort) res.iconSort = environment.column.asc_sort;
      else {
        if (iconSort == environment.column.asc_sort) res.iconSort = environment.column.desc_sort;
        else res.iconSort = environment.column.asc_sort;
      }
      res.columnSort = fieldSort;
      res.typeSort = iconSort == environment.column.asc_sort ? environment.column.desc : environment.column.asc;
      resolve(res);
    })
    return promise;
  }

}