/* tslint:disable */

/**
 */
export class JobFind {
    page?: number;
    limit?: number;
    search?: string;
    sortBy?: string;
    sortType?: string;
    status?: string;
    myJob?: string;
    fromDate?: string;
    toDate?: string;
    softwareId?: number;
    projectUuid?: string;
    userId?: number;
    year?: number;
    fromWeek?: number;
    toWeek?: number;
}
