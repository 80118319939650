import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../api/services';
import { User } from '../../api/models';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../shared/common.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit, AfterViewInit {

  public user: User;
  // public contact: string = environment.contact;
  // public phone: string = environment.phone;
  public emailInvalid = false;
  //public socket;
  public typePass = 'password';

  constructor(
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private authService: AuthService, 
    private commonService: CommonService,
    private router: Router,
    private app: AppComponent
  ) {
    this.user = new User();
  }

  ngOnInit() {
    this.spinner.hide();
    clearTimeout(JSON.parse(localStorage.getItem('timelogin')));
  }

  ngAfterViewInit() {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/manager']);
    }
  }

  onLogin() {
    // let user = {
    //         name: 'admin',
    //         email: 'admin@gmail.com',
    //         avatar: '',
    //         role: 'admin'
    //       }
    //       localStorage.setItem('token', 'rwrwerwe');
    //       localStorage.setItem('refresh_token', 'gdsgssdgs');
    //       this.commonService.setUser(user);
    //       this.app.tokenTracking();
    //       this.spinner.hide();
    //       this.router.navigate(['/manager']);
    if (!this.user.email ) {
      swal('Warning!', this.translate.instant('user.login.email.required'), 'warning');
      return;
    }
    if (this.commonService.isEmailInvalid(this.user.email )) {
      swal('Warning!', this.translate.instant('user.login.email.invalid'), 'warning');
      this.emailInvalid = true;
      return;
    }
    if (!this.user.password) {
      swal('Warning!', this.translate.instant('user.login.password.required'), 'warning');
      return;
    }
    this.spinner.show();
    this.authService.login(this.user).subscribe(
      res => {
        let user = {
          name: res.user['name'],
          email: res.user['email'],
          avatar: res.user['avatar'],
          role: res.user['role'].name
        }
        localStorage.setItem('token', res.access_token);
        localStorage.setItem('refresh_token', res.refresh_token);
        this.commonService.setUser(user);
        this.app.tokenTracking();
        this.spinner.hide();
        this.router.navigate(['/manager']);
      },
      err => {
        this.spinner.hide();
        if (err.status == 429) {
          swal('Warning!', err.error.errors.email[0], 'warning');
        } else {
          swal('Warning!', this.translate.instant('user.login.faild'), 'warning');
        }
      }
    );
  }

  showPass() {
    if (this.typePass == 'password') {
      this.typePass = 'text';
    } else {
      this.typePass = 'password';
    }
  }

}
