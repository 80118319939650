/* tslint:disable */
import { Dashboard } from './dashboard';
import { DataChart } from './data-chart';

/**
 */
export class Statistic {
    dashboard?: Dashboard;
    chart?: DataChart;
}
