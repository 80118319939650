<div class="container-fluid main-login d-flex align-items-center">
  <div class="row justify-content-center">
    <div class="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-12">
      <form class="form-signin" (ngSubmit)="onLogin()">
        <img class="mb-5 img-fluid" src="assets/image/dfm-logo.png">
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1"><i class="fa fa-user" aria-hidden="true"></i></span>
          <input type="text" class="form-control" [ngClass]="emailInvalid ? 'is-invalid' : ''" placeholder="Username"
            [(ngModel)]="user.email" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text"><i class="fa fa-lock" aria-hidden="true"></i></span>
          <input type="{{ typePass }}" class="form-control password" placeholder="Password" [(ngModel)]="user.password"
            [ngModelOptions]="{standalone: true}" autocomplete="off">
          <i class="fa fa-eye show-password" aria-hidden="true" (click)="showPass()"></i>
        </div>
        <button class="btn btn-lg btn-primary btn-block mt-4 px-5 btn-action" type="submit">Login</button>
        <p class="mt-3 mb-3 text-muted"><a href="">Forgot password</a></p>
      </form>
    </div>
  </div>
</div>