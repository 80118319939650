/* tslint:disable */
import { JobFind } from './job-find';

/**
 */
export class Job {
    uuid?: string;
    name?: string;
    project_name?: string;
    reference?: string;
    user_name?: string;
    nb_cpu?: number;
    submit_at?: string;
    start_at?: string;
    status?: number;
    cpu_time?: number;
    prioritize?: number;
    filter?: JobFind;
}
