/* tslint:disable */

/**
 */
export class Dashboard {
    project?: number;
    running?: number;
    job?: number;
    queue?: number;
    finish?: number;
    totalHours?: number;
}
