import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';

import { AuthService } from './services/auth.service';
import { JobsService } from './services/jobs.service';
import { PluginsService } from './services/plugins.service';
import { ProjectsService } from './services/projects.service';
import { SoftwaresService } from './services/softwares.service';
import { UsersService } from './services/users.service';

/**
 * Module that provides instances for all API services
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
   AuthService,
   JobsService,
   PluginsService,
   ProjectsService,
   SoftwaresService,
   UsersService
  ],
})
export class ApiModule { }
