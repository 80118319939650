/* tslint:disable */

/**
 */
export class JobCreate {
    software?: number;
    job_name?: string;
    project?: string;
    reference?: string;
    directory?: string;
    nb_cpu?: number;
    params?: {};
    prioritize?: number;
}
