export const environment = {
    production: false,
    pagination_limit: 15,
    pagination_size: 5,
    link_api: 'https://api.abajob.dfm-europe.com',
    web_socket: 'wss://ws.abajob.dfm-europe.com/job',
    time_refresh_token: 3240000, //3240000
    column: {
        sort: "fa-sort",
        asc_sort: 'fa-sort-up',
        desc_sort: 'fa-sort-down',
        asc: 'asc',
        desc: 'desc',
        name_sort: 'name',
        project_name_sort: 'project_name',
        dev_eui_sort: 'date',
        reference_sort: 'reference',
        user_name_sort: 'user_name'
    },
};
